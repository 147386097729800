import { graphql } from 'gatsby'

import { TeamMember } from './TeamMember'

export default TeamMember

export const query = graphql`
  fragment TeamMemberFragment on ContentfulTeamMember {
    id
    name
    photo {
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid
      }
      ... FluidImageFragment
    }
    jobDescription
  }
`
