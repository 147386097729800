import React from 'react'

import Section from '../../layout/Section'
import TeamMember from '../../components/TeamMember'

interface Props {
  teamMembers: SectionItems<TeamMember>
}

export const TeamMembersSection: React.FC<Props> = ({ teamMembers }) => (
  <Section headline={teamMembers.headline} bottomPadding='small' topPadding='small' light>
    <div className='grid-x grid-margin-x grid-margin-y'>
      {teamMembers.items.map(teamMember => (
        <TeamMember key={teamMember.name} teamMember={teamMember}/>
      ))}
    </div>
  </Section>
)
