import React from 'react'

import Contact from '../../components/Contact'
import { SmallHero } from '../../components/Hero'
import { extractTeamMemberSection } from '../../contentful'
import { SectionType } from '../../enums'
import Layout from '../../layout/Layout'
import { LeadSection } from '../../page-components/Home/LeadSection'
import { TeamMembersSection } from './TeamMembersSection'

import styles from './who-we-are.module.scss'

interface Props {
  pageContext: PageContext
}

export const WhoWeAre: React.FC<Props> = ({ pageContext }) => {
  const { leadData, teamMembers } = extractSections(pageContext.sections)
  return (
    <Layout pageContext={pageContext}>
      <SmallHero
        headline={pageContext.headline}
        addon={{
          addon: <img src='/images/illustrations/planet3.svg' alt='Planet illustration'/>,
          className: styles.heroAddon,
        }}
      />
      <LeadSection leadData={leadData}/>
      <TeamMembersSection teamMembers={teamMembers}/>
      <Contact/>
    </Layout>
  )
}

interface Sections {
  leadData?: SectionSource<LeadSource>
  teamMembers: SectionItems<TeamMember>
}

const extractSections = (sections: SectionSource[]): Sections => {
  const teamMemberData = sections.find(section => section.type === SectionType.TeamMember)
  const leadData = sections.find(section => section.type === SectionType.Lead)

  return {
    teamMembers: extractTeamMemberSection(teamMemberData as SectionItemsSource<TeamMemberSource>),
    leadData: leadData as SectionSource<LeadSource>,
  }
}
