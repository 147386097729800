import React from 'react'
import Img from 'gatsby-image'

import Card from '../Card'

import styles from './team-member.module.scss'

interface Props {
  teamMember: TeamMember
}

export const TeamMember: React.FC<Props> = ({ teamMember }) => (
  <Card className={`${styles.teamMember} cell large-4 medium-6 small-12`}>
    <Img
      className={styles.photo}
      fluid={teamMember.photo.fluid}
      alt={teamMember.photo.alt || `${teamMember.name} photo`}
    />
    <h6>{teamMember.name}</h6>
    <p className='pink'><i><strong>{teamMember.jobDescription}</strong></i></p>
  </Card>
)
